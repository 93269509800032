@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@include spb.set-typography;


// Remove annoying 5px margin
body, html {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/*
Header in tables
*/
th {
  color: rgb(0 0 0 / 54%) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}


/**
Hide scrollbar in table
 */
.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

/**
All icons must me centred instead of appearing a bit to the left in their "boxes"
 */
button {
  .mat-icon {
    margin: 0 !important;
  }
}


// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}
